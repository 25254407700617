html, body, #root {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	scrollbar-width: none;
}

#map {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;	
}

div.ant-spin-blur {
	opacity: 1;
}


.ant-spin.ant-spin-lg.ant-spin-spinning {
	max-height: 1000px;
}

i.ant-spin-dot-item {
	background-color: #ff0000
}

.ol-control button{ 
	background-color: #1890ff !important;
	border-color: #ffffff;
	border-radius: 50% !important;
	height: 32px  !important;
	width: 32px  !important;
}

.ol-control button:hover{ 
	border: 1px solid #ffffff !important;
}

.ol-zoom .ol-zoom-in {
	position: fixed !important;
	top: 10px !important;
	left: 10px !important;
	font-size: 150%;
	font-weight : bold ;
	padding-top: 9px;
	padding-bottom: 20px;
}

.ol-zoom .ol-zoom-out {
	position: fixed !important;
	top: 50px !important;
	left: 10px !important;
	font-size: 150%;
	font-weight : bold ;
	padding-top: 9px;
	padding-bottom: 20px;
}

/*
.ol-rotate-reset {
	position: fixed !important;
	top: 50px !important;
	right: 10px !important;
}
*/

.ol-control { 
	background-color: rgb(255, 255, 255, 0) !important;
}

.ol-scale-line, .ol-scale-bar {
	bottom: 35px !important;
}

.ggw-dbo, .ggw-settings{
	opacity: 0.9;
}

div.ant-form-item {
	margin-bottom: 1px; 
}  

div.ant-col.ant-form-item-label {
	padding: 0px;
	margin-bottom: 1px; 
	background-color: #dddddd
}
div.ant-row {
	margin-top: 0px;
}

div.ant-drawer-body {
	padding: 8px;
}

div.ant-card-body {
	padding: 10px; 
	padding-top: 10px;
}

div.ant-carousel {
	font-size: 1px;
}

.ant-btn {
	margin-top: 0px;
}

span.ant-radio-disabled + span{
	color:rgba(40, 40, 40, 0.85);
}

span.ant-tree-switcher {
	width: 1px;
}

div.ant-carousel div.slick-slide {
	background: #999999;
	overflow: hidden;	
	text-align: center; 
}

.ol-popup {
	font-size: 150%;
	background-color: white;
	opacity: 0.8;
}		
.ol-popup-content {
	min-width: 250px;
}		